import Headroom from "headroom.js";
import Swup from "swup";
import SwupJsPlugin from "@swup/js-plugin";
import SwupHeadPlugin from "@swup/head-plugin";
import SwupScriptsPlugin from "@swup/scripts-plugin";
import SwupGtmPlugin from "@swup/gtm-plugin";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  const logo = document.querySelector('.main-logo');
  const header = document.querySelector('.main-header');
  const menuTrigger = document.querySelector('.menu-trigger');
  const menu = document.querySelector('.main-navigation');
  const menuItems = document.querySelectorAll('.main-navigation__link');

  function handleDOMContentLoaded() {
    const headroom  = new Headroom(logo);
    headroom.init();

    menuTrigger.addEventListener('click', handleMenuTrigger);
    menuItems.forEach((el) => el.addEventListener('click', handleMenuTrigger));

    const swup = new Swup({
      containers: [
        '.main-content',
        '.js-content'
      ],
      plugins: [
        new SwupJsPlugin({
          animations: [
            {
              from: '(.*)',
              to: '(.*)',
              out: async () => {
                const changer = document.querySelector('.changer');
                await changer.animate({ height: ['0', '100dvh'], easing: 'ease-out' }, { duration: 500, fill: "both" }).finished
              },
              in: async () => {
                const changer = document.querySelector('.changer');
                await changer.animate({ height: ['100dvh', '0'], easing: 'ease-in' }, { duration: 500, fill: "both" }).finished
              }
            }
          ]
        }),
        new SwupHeadPlugin({
          persistTags: '[data-keep], style'
        }),
        new SwupScriptsPlugin(),
        new SwupGtmPlugin()
      ]
    });
  }

  function handleMenuTrigger(e) {
    e.preventDefault();

    if (menuTrigger.classList.contains('menu-trigger--active')) {
      menuTrigger.classList.remove('menu-trigger--active');
      header.classList.remove('main-header--active');
      menu.classList.remove('main-navigation--active');
    } else {
      menuTrigger.classList.add('menu-trigger--active');
      header.classList.add('main-header--active');
      menu.classList.add('main-navigation--active');
    }
  }

})();
